import React from "react"
import "twin.macro"
import { IUser, UserTypeEnum } from "app/User/types"
import { StyledEditIcon } from "routes/Alarms/components/AlarmForm"
import { EditorPanelHeader } from "routes/Alarms/components/editor/Header"
import { Group as ListGroup } from "lib/List/Group"
import { StyledBaseResult } from "app/ResultListGroup/Result"
import FEATURE, { USERRIGHTS } from "data/featureFlags"

type UserMeta = {
  title: string
  description: string
  icon: string
  type: UserTypeEnum
}

type DefaultUser = {
  user: IUser
  meta: UserMeta
}

const defaultUserTypes: DefaultUser[] = [
  {
    user: {
      id: "default_admin",
      is_admin: 1,
      is_token_whitelisted: 1,
      active: 1,
      language: "da-dk",
      feature: [],
    },
    meta: {
      title: "Administrator",
      description: "Bruger med fuld systemadgang",
      type: UserTypeEnum.Admin,
    },
  },
  {
    user: {
      id: "default_user",
      is_admin: 0,
      feature: [FEATURE.START, FEATURE.HISTORY, USERRIGHTS.WRITE],
      active: 1,
      language: "da-dk",
    },
    meta: {
      title: "Bruger",
      description: "Bruger med begrænset adgang og rettigheder",
      type: UserTypeEnum.User,
    },
  },
  {
    user: {
      id: "default_fitter",
      is_admin: 0,
      is_token_whitelisted: 1,
      active: 1,
      language: "da-dk",
      feature: [
        FEATURE.START,
        FEATURE.SERVICE,
        FEATURE.GROUPS,
        FEATURE.DEVICEMOUNTING,
        USERRIGHTS.WRITE,
        FEATURE.DEVICESETTINGS,
      ],
    },
    meta: {
      title: "Montør",
      description: "Bruger med adgang til opsætning af enheder",
      type: UserTypeEnum.Installer,
    },
  },
]

export const UserTypeSelector: React.FC<{
  onSelectUserType: (user: IUser) => void
}> = ({ onSelectUserType }) => {
  return (
    <>
      <EditorPanelHeader>Ny bruger</EditorPanelHeader>
      <ListGroup tw="space-y-4" as="div">
        {defaultUserTypes.map((user, i) => {
          return (
            <StyledBaseResult
              key={user.user.id}
              tw="border-b flex shadow-none p-4 px-6 border border-solid border-brand-gray-light hover:(shadow-card bg-white)"
              onClick={() => onSelectUserType(user.user)}
            >
              <span tw="flex flex-col flex-grow">
                <span tw="flex text-2xl">
                  <span tw="text-xl font-bold">{user.meta.title}</span>
                  <span
                    tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
                    onClick={() => onSelectUserType(user.user)}
                  >
                    <span tw="text-lg">Vælg</span>
                    <StyledEditIcon icon="chevron-right" size="sm" />
                  </span>
                </span>
                <span tw="block text-lg opacity-60">
                  {user.meta.description}
                </span>
              </span>
            </StyledBaseResult>
          )
        })}
      </ListGroup>
    </>
  )
}
