import { CompanyContext } from "app/Company/context"
import { ConversationWidget } from "app/Conversations"
import I18n from "app/I18n"
import { UserContext } from "app/User/context"
import { useUser } from "app/User/hooks"
import { MenuTypeEnum } from "context/App/app.types"
import FEATURE, { COMPANYFUNCTIONS } from "data/featureFlags"
import { ITapBarItem } from "lib/Navigation/navigation.types"
import React, { useContext } from "react"

export const useNavigationItems = () => {
  const {
    state: { company },
  } = useContext(CompanyContext)
  const {
    state: { user },
  } = useContext(UserContext)
  const { deviceMountingUrl } = useUser()

  const tapBarNavigationItems: ITapBarItem[] = [
    {
      key: "map",
      icon: "map",
      link: "/app",
      title: <I18n strKey="nav_start" />,
      featureFlag: FEATURE.START,
    },
    {
      key: "service",
      icon: "car-mechanic",
      link: "/app/service",
      title: <I18n strKey="nav_service" />,
      featureFlag: FEATURE.SERVICE,
      partiallyActive: true,
    },
    {
      key: "history",
      icon: "history",
      link: "/app/history",
      title: <I18n strKey="nav_history" />,
      featureFlag: FEATURE.HISTORY,
      partiallyActive: true,
    },
    {
      key: "messenger",
      render: () => <ConversationWidget />,
      title: "Messenger",
      activeOnKey: MenuTypeEnum.Conversations,
    },
  ]
    .filter((item) => {
      if (
        item.key === "messenger" &&
        (!company?.msisdn ||
          (!user.is_admin &&
            !user?.feature.includes(COMPANYFUNCTIONS.MESSENGER)))
      )
        return null
      return item
    })
    .filter(Boolean)

  if (deviceMountingUrl && user?.feature.includes(FEATURE.DEVICEMOUNTING)) {
    tapBarNavigationItems.push({
      key: "mounting",
      icon: "hard-hat",
      link: deviceMountingUrl,
      title: "Montering",
      featureFlag: FEATURE.DEVICEMOUNTING,
      partiallyActive: true,
    })
  }

  tapBarNavigationItems.push({
    key: "nav_toggle",
    icon: "menu",
    title: "Mere",
    isNavToggle: true,
  })

  return { tapBarNavigationItems }
}
