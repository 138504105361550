import React, { useContext } from "react"
import "twin.macro"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "lib/Icon"
import I18n from "app/I18n"
import NavLink from "components/Navigation/NavLink"
import NavItem from "./NavItem"
import FeatureComponent from "app/FeatureComponent"
import FEATURE, { COMPANYFEATURES } from "data/featureFlags"
import { CompanyContext } from "app/Company/context"
import { UserContext } from "app/User/context"
import { useUser } from "app/User/hooks"

const StyledNavigation = styled.ul`
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    margin: 0 2rem;
  `}
`

function Navigation({ children, onSelectNavItem, ...props }) {
  const {
    state: {
      company: { features },
    },
  } = useContext(CompanyContext)
  const {
    state: { user },
  } = useContext(UserContext)
  const { deviceMountingUrl } = useUser()
  return (
    <StyledNavigation {...props}>
      {children ? (
        children
      ) : (
        <>
          <FeatureComponent feature={FEATURE.START} user={user}>
            <NavItem>
              <NavLink to="/app" activeClassName="active" snappy>
                <Icon icon="map" />
                <span>
                  <I18n strKey="nav_start" />
                </span>
              </NavLink>
            </NavItem>
          </FeatureComponent>
          {Object.values(features).includes(COMPANYFEATURES.DASHBOARD) && (
            <FeatureComponent feature={COMPANYFEATURES.DASHBOARD} user={user}>
              <NavItem>
                <NavLink
                  to="/app/dashboard"
                  activeClassName="active"
                  partiallyActive={true}
                  snappy
                  onClick={onSelectNavItem}
                >
                  <Icon icon="chart-line" />
                  <span>
                    <I18n strKey="nav_dashboard" />
                  </span>
                </NavLink>
              </NavItem>
            </FeatureComponent>
          )}
          <FeatureComponent feature={FEATURE.SERVICE} user={user}>
            <NavItem>
              <NavLink
                to="/app/service"
                activeClassName="active"
                partiallyActive={true}
                snappy
              >
                <Icon icon="car-mechanic" />
                <span>
                  <I18n strKey="nav_service" />
                </span>
              </NavLink>
            </NavItem>
          </FeatureComponent>
          <FeatureComponent feature={FEATURE.HISTORY} user={user}>
            <NavItem>
              <NavLink
                to="/app/history"
                activeClassName="active"
                partiallyActive={true}
                snappy
              >
                <Icon icon="history" />
                <span>
                  <I18n strKey="nav_history" />
                </span>
              </NavLink>
            </NavItem>
          </FeatureComponent>

          {Object.values(features).includes(COMPANYFEATURES.REPORTS) && (
            <FeatureComponent feature={COMPANYFEATURES.REPORTS} user={user}>
              <NavItem>
                <NavLink
                  to="/app/reports"
                  activeClassName="active"
                  partiallyActive={true}
                  snappy
                  onClick={onSelectNavItem}
                >
                  <Icon icon="file-chart-line" />
                  <span>
                    <I18n strKey="nav_reports" />
                  </span>
                </NavLink>
              </NavItem>
            </FeatureComponent>
          )}
          {deviceMountingUrl && user?.is_admin === 0 && (
            <FeatureComponent user={user} feature={FEATURE.DEVICEMOUNTING}>
              <NavItem>
                <NavLink href={deviceMountingUrl} tw="flex items-center">
                  <Icon icon="hard-hat" />
                  <span>Enhedsmontering</span>
                  <Icon icon="external-link"></Icon>
                </NavLink>
              </NavItem>
            </FeatureComponent>
          )}
        </>
      )}
    </StyledNavigation>
  )
}

export default Navigation

Navigation.defaultProps = {
  children: null,
}

Navigation.propTypes = {
  children: PropTypes.node,
}
